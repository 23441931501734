import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  handleSubmit(event) {
    const val = this.inputTarget.value;
    if (event.submitter.value == "suggest_response") {
      // Do not preventDefault for suggest_response
      return;
    }
    if (val.trim() === "") {
      event.preventDefault();
      return;
    }
  }

  handleKeydown(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.element.requestSubmit();
    }
  }
}
